import React from "react";
import Navegacion from "../components/Navegacion/Navegacion";
import { Row, Col, Image, Container} from "react-bootstrap";
import styled from "styled-components";
import Form from "../components/Footer/form/FormTrabajeConNosotros";
import Nosotros from "../assets/img/unete-a-este-gran-equipo-cosinte.jpg";
import Frase from "../components/frase/Frase";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import TituloPagina from "../components/tituloPagina/TituloPagina";
import TituloSeccion from "../components/common/TituloSeccion";


export default function TrabajeConNosotros() {
  const ldJson = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": "https://www.cosinte.com/trabaje-con-nosotros/",
        url: "https://www.cosinte.com/trabaje-con-nosotros/",
        name: "Trabaje con nosotros - Cosinte",
        isPartOf: { "@id": "https://www.cosinte.com/#website" },
        datePublished: "2021-06-19T00:00:02+00:00",
        dateModified: "2023-04-10T23:13:00+00:00",
        breadcrumb: {
          "@id": "https://www.cosinte.com/trabaje-con-nosotros/#breadcrumb",
        },
        inLanguage: "es",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://www.cosinte.com/trabaje-con-nosotros/"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://www.cosinte.com/trabaje-con-nosotros/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Portada",
            item: "https://www.cosinte.com/",
          },
          { "@type": "ListItem", position: 2, name: "Trabaje con nosotros" },
        ],
      },
      {
        "@type": "WebSite",
        "@id": "https://www.cosinte.com/#website",
        url: "https://www.cosinte.com/",
        name: "Cosinte",
        description: "Expertos en Seguridad Preventiva",
        potentialAction: [
          {
            "@type": "SearchAction",
            target: {
              "@type": "EntryPoint",
              urlTemplate: "https://www.cosinte.com/?s={search_term_string}",
            },
            "query-input": "required name=search_term_string",
          },
        ],
        inLanguage: "es",
      },
    ],
  };
  return (
    <div>
      <Helmet>
        <title>Trabaje con nosotros - Cosinte</title>
        <link
          rel="canonical"
          href="https://www.cosinte.com/trabaje-con-nosotros/"
        />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:title" content="Trabaje con nosotros - Cosinte" />
        <meta
          property="og:url"
          content="https://www.cosinte.com/trabaje-con-nosotros/"
        />
        <meta property="og:image" content="../../assets/logo-cosinte-blanco.png" />
        <meta property="og:description" content="ÚNETE A ESTE GRAN EQUIPO
        En Cosinte creemos que uno de nuestros factores diferenciadores es nuestro talento humano; 
        somos un equipo de profesionales con diversos perfiles, que trabajamos con el objetivo de generar una CULTURA de PREVENCIÓN." />
        <meta
          property="article:modified_time"
          content="2023-04-10T23:13:00+00:00"
        />
        <meta name="twitter:data1" content="3 minutos" />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>
      <Navegacion />
    
      <TituloPagina titulo={'TRABAJE CON NOSOTROS'} escala={0.5} minHeight="100vh" />
      <TituloSeccion
        titulo={"ÚNETE A ESTE GRAN EQUIPO"}
        isLeftAlign={true}
        tituloSize={"var(--fs-heading-xl)"}
        subtituloSize={"var(--fs-heading-md)"}
      />
   
      <Container >
      <Row className="pt-5">
        <Col md={6} className="pe-lg-5 mb-5">
          <Parrafo>
            <span>
              En Cosinte creemos que uno de nuestros factores diferenciadores es
              nuestro talento humano; somos un equipo de profesionales con
              diversos perfiles, que trabajamos con el objetivo de generar una{" "}
              <strong>CULTURA</strong> de <strong>PREVENCIÓN</strong>.<br></br>
              <br></br>
              Si estás interesado en trabajar con nosotros, te invitamos a que
              nos dejes a continuación tu hoja de vida:
            </span>
          </Parrafo>
          <Imagen
            src={Nosotros} alt="Trebaje con nosotros"
          ></Imagen>
        </Col>
        <Col md={6}>
          <Form></Form>
        </Col>
      </Row>
      </Container>
      <Frase></Frase>
      <Footer></Footer>
    </div>
  );
}


const Parrafo = styled.h2`
  font-size: var(--fs-body);
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  word-wrap: break-word;
  margin-bottom: 2rem;
  line-height: 1.6;
`;

const Imagen = styled(Image)`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
`;
