import React from 'react';
import {ParallaxBanner} from 'react-scroll-parallax';
import legal2 from '../../assets/img/legal2.jpg';
import fondo from '../../assets/img/fondo1.jpg';
import {Row, Col, Image, Container} from 'react-bootstrap';
import InfoElement from '../Footer/contactInfo/InfoElement';
import {Zoom} from 'react-awesome-reveal';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import './Certificaciones.scss';

gsap.registerPlugin(ScrollTrigger);

export default function Certificaciones() {

  /*
  const containerRef = useRef(null);
  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const box = self.selector('.slide__img');
        gsap.fromTo(
          //Inicial is outside right of screen and then moves a little to the left to seem like it's coming from outside the screen
          box,
          { x: "100vw" },
          {
            x: "82vw",
            duration: 3,
            delay: 1.2,
            scrollTrigger: {
              trigger: box,
              scrub: 1,
              markers: false,
              start: "top 100%",
              end: "center 25%",
            }
          }
        );
    }, containerRef); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);
  */

  return (
    <Container fluid className="p-0 mt-5 position-relative">
      <ParallaxBanner layers={[{image: fondo, speed: -15}]}>
        <div className='backdrop'></div>
        <Container className="certificaciones__container p-3 p-lg-5 m-5 mx-auto">
          <Row className="d-flex align-items-start">
            <Col lg={6} className="certificaciones__description__container">
              <h2 className="certificaciones__title">
                Contamos con una estructura legal sólida, que garantiza la calidad de nuestros servicios
              </h2>
              <Image src={legal2} alt="Marco legal" fluid className="certificaciones__image" />
              <p className="certificaciones__description">
                <strong>Marco legal</strong>
                <br />
                Tenemos registros y acreditaciones que nos permiten ejercer como
                una empresa especialista en gestión de riesgos corporativos;
                estamos afiliados a la Cámara de Comercio desde 1996.
              </p>
            </Col>
            <Col lg={6} className="mt-5 mt-lg-0">
              <Zoom duration="500" triggerOnce={true} cascade={true}>
                <InfoElement
                  titulo="Licencia de Funcionamiento"
                  icono="\e058"
                  iconSize="55px"
                  titleSize="20px"
                  isWhiteMode={true}
                  children={
                    <p>
                      Expedida por la Superintendencia de Vigilancia y Seguridad
                      Privada, resolución no. 03667 del 30 de noviembre de 2018.
                    </p>
                  }></InfoElement>
                <InfoElement
                  titulo="Gestión de Calidad"
                  icono="\e052"
                  iconSize="55px"
                  titleSize="20px"
                  isWhiteMode={true}
                  children={
                    <p>
                      Certificados en Gestión de Calidad por la norma
                      ISO:9001:2015.
                    </p>
                  }></InfoElement>
                <InfoElement
                  titulo="Gestión de Riesgos"
                  icono="\e06c"
                  iconSize="55px"
                  titleSize="20px"
                  isWhiteMode={true}
                  children={
                    <p>
                      Certificados en Gestión de Riesgos por la norma
                      ISO:28000:2007.
                    </p>
                  }></InfoElement>
                <InfoElement
                  titulo="Programa ANC - BASC"
                  icono="\e057"
                  iconSize="55px"
                  titleSize="20px"
                  isWhiteMode={true}
                  children={
                    <p>
                      Certificada por BASC en cumplimiento de los estándares OEA
                      y C-TPAT Programa ANC - Asociado de Negocio Confiable.
                    </p>
                  }></InfoElement>
                <InfoElement
                  titulo="Ética Mercantil"
                  icono="\e0d8"
                  iconSize="55px"
                  titleSize="20px"
                  isWhiteMode={true}
                  children={
                    <p>
                      Sello de Ética Mercantil de la Federación Nacional de
                      Comerciantes FENALCO.
                    </p>
                  }></InfoElement>
                <InfoElement
                  titulo="Gestión de Seguridad"
                  icono="\e0f0"
                  iconSize="55px"
                  titleSize="20px"
                  isWhiteMode={true}
                  children={
                    <p>
                      Certificados en Gestión de Seguridad por la norma BASC.
                    </p>
                  }></InfoElement>
              </Zoom>
            </Col>
          </Row>
        </Container>
      </ParallaxBanner>
    </Container>
  );
}
