import React from 'react';
import Navegacion from '../components/Navegacion/Navegacion';
import Footer from '../components/Footer/Footer';
import Historia from '../components/historia/Historia';
import Frase from '../components/frase/Frase';
import Opiniones from '../components/opiniones/Opiniones';
import Valores from '../components/valores/Valores';
import Portafolio from '../components/portafolio/Portafolio';
import {Helmet} from 'react-helmet';
import Hero from '../components/hero/Hero';


function Inicio() {
  const ldJson = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'WebPage',
        '@id': 'https://www.cosinte.com/',
        url: 'https://www.cosinte.com/',
        name: 'Cosinte - Expertos en Seguridad Preventiva',
        isPartOf: {'@id': 'https://www.cosinte.com/#website'},
        datePublished: '2021-06-15T16:30:20+00:00',
        dateModified: '2023-02-24T17:20:49+00:00',
        breadcrumb: {'@id': 'https://www.cosinte.com/#breadcrumb'},
        inLanguage: 'es',
        potentialAction: [
          {'@type': 'ReadAction', target: ['https://www.cosinte.com/']},
        ],
      },
      {
        '@type': 'BreadcrumbList',
        '@id': 'https://www.cosinte.com/#breadcrumb',
        itemListElement: [{'@type': 'ListItem', position: 1, name: 'Portada'}],
      },
      {
        '@type': 'WebSite',
        '@id': 'https://www.cosinte.com/#website',
        url: 'https://www.cosinte.com/',
        name: 'Cosinte',
        description: 'Expertos en Seguridad Preventiva',
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: 'https://www.cosinte.com/?s={search_term_string}',
            },
            'query-input': 'required name=search_term_string',
          },
        ],
        inLanguage: 'es',
      },
    ],
  };


  return (
    <>
      <Helmet>
        <title>Cosinte - Expertos en Seguridad Preventiva</title>
        <link rel="canonical" href="https://www.cosinte.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Cosinte - Expertos en Seguridad Preventiva"
        />
        <meta property="og:url" content="https://www.cosinte.com/" />
        <meta property="og:image" content="../../assets/logo-cosinte-blanco.png" />
        <meta property="og:description" content="Organizaciónfuncional, especializada en el área de seguridad, con filosofía preventiva; nuestro principal interés radica en adelantarnos a cualquier fenómeno que atente contra la integridad personal o los intereses económicos de nuestros clientes" />
        <meta
          property="article:modified_time"
          content="2023-02-24T17:20:49+00:00"
        />
        <meta name="twitter:data1" content="22 minutos" />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        <script>{`gtag('event', 'conversion', {'send_to': 'AW-999486799/TKebCLTrkdYYEM_qy9wD'});`}</script>
      </Helmet>
      <Navegacion transparent></Navegacion>
      <Hero></Hero>
      <Valores></Valores>
      <Frase variant={'container__frase--white'}></Frase>
      <Portafolio></Portafolio>
      <Historia></Historia>
      <Opiniones></Opiniones>
      <Footer></Footer>
    </>
  );
}

export default Inicio;
