import React from "react";
import Navegacion from "../components/Navegacion/Navegacion";
import TituloSeccion from "../components/common/TituloSeccion";
import Form from "../components/Footer/form/Form";
import { Row, Col, Container } from "react-bootstrap";
import ContactInfoAlternative from "../components/Footer/contactInfo/ContactInfoAlternative";
import { Helmet } from "react-helmet";
import TituloPagina from "../components/tituloPagina/TituloPagina";
import Image from "react-bootstrap/Image";
import imagen from "../assets/img/contactenos.png";

export default function Contactenos() {
  const ldJson = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": "https://www.cosinte.com/contactenos/",
        url: "https://www.cosinte.com/contactenos/",
        name: "Contáctenos - Cosinte",
        isPartOf: { "@id": "https://www.cosinte.com/#website" },
        datePublished: "2021-06-15T18:15:09+00:00",
        dateModified: "2023-04-10T23:10:38+00:00",
        breadcrumb: {
          "@id": "https://www.cosinte.com/contactenos/#breadcrumb",
        },
        inLanguage: "es",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://www.cosinte.com/contactenos/"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://www.cosinte.com/contactenos/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Portada",
            item: "https://www.cosinte.com/",
          },
          { "@type": "ListItem", position: 2, name: "Contáctenos" },
        ],
      },
      {
        "@type": "WebSite",
        "@id": "https://www.cosinte.com/#website",
        url: "https://www.cosinte.com/",
        name: "Cosinte",
        description: "Expertos en Seguridad Preventiva",
        potentialAction: [
          {
            "@type": "SearchAction",
            target: {
              "@type": "EntryPoint",
              urlTemplate: "https://www.cosinte.com/?s={search_term_string}",
            },
            "query-input": "required name=search_term_string",
          },
        ],
        inLanguage: "es",
      },
    ],
  };
  return (
    <div>
      <Helmet>
        <title>Contáctenos - Cosinte</title>
        <link rel="canonical" href="https://www.cosinte.com/nosotros/" />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:title" content="Contáctenos - Cosinte" />
        <meta property="og:image" content="../../assets/logo-cosinte-blanco.png" />
        <meta property="og:description" content="¿TIENE ALGUNA DUDA?
        Para Cosinte es de suma importancia conocer y resolver todas sus solicitudes" />
        <meta
          property="og:url"
          content="https://www.cosinte.com/contactenos/"
        />
        <meta
          property="article:modified_time"
          content="2023-04-10T23:10:38+00:00"
        />
        <meta name="twitter:data1" content="10 minutos" />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        <script>{`gtag('event', 'conversion', {'send_to': 'AW-999486799/TKebCLTrkdYYEM_qy9wD'});`}</script>
      </Helmet>
      <Navegacion/>
      <div className="position-relative">
      <TituloPagina titulo={"CONTÁCTENOS"} escala={0.8}/> 
      <Container>
        <TituloSeccion
          className="mb-5 pb-5 mt-3"
          titulo={'¿TIENE ALGUNA DUDA?'}
          isLeftAlign={true}
          tituloSize={"var(--fs-heading-xl)"}
          subtituloSize={"var(--fs-heading-md)"}
          subtitulo={ 'Para Cosinte es de suma importancia conocer y resolver todas sus solicitudes'}>
        </TituloSeccion>
        <Row>
          <Col md={6}>
            <Form></Form>
          </Col>
          <Col md={6}>
            <div className="mb-5 pb-5 mb-lg-0 pb-lg-0">
              <ContactInfoAlternative />
            </div>
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  );
}
