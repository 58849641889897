import React from "react";
import video from "../../assets/video/hero.mp4";
import Button from "../common/Button";
import { Container, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Hero.scss";

export default function Hero() {
    const navigate = useNavigate();

    return (
        <div className="hero">
            <video autoPlay loop muted className="hero__video" preload="auto">
                <source src={video} type="video/mp4" />
                Tu navegador no soporta el elemento de video.
            </video>
            <div className="hero__content ">
                <div className="backdrop__video"></div>{" "}
                {/* Fondo semitransparente */}
                <Carousel
                    controls={false}
                    indicators={true}
                    interval={5000}
                    pause={false}
                    className="hero__carousel"
                >
                    <Carousel.Item>
                        <Container className="hero__text__container">
                            <h1 className="hero__text mb-5">
                                ¿Sabe qué es SAGRILAFT y cómo implementarlo en
                                su empresa?
                            </h1>
                            <Button
                                variant="hero"
                                size="lg"
                                onClick={() => navigate("/contactenos")}
                            >
                                Contáctenos
                            </Button>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container className="hero__text__container">
                            <h2 className="hero__text mb-2">
                                Inteligencia preventiva
                            </h2>
                            <p className="hero__text__description mb-5">
                                Identificamos con anticipación el riesgo que no
                                se ve
                            </p>
                            <Button
                                variant="hero"
                                onClick={() => navigate("/contactenos")}
                                size="lg"
                            >
                                Contáctenos
                            </Button>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container className="hero__text__container">
                            <h2 className="hero__text hero__text--small mb-3">
                                Contamos con Investigadores, Analistas de
                                Información Especialistas en Grafología,
                                Consultores y Asesores en Gestión de Riesgo
                                Expertos en el área de Contra Inteligencia
                            </h2>
                            <p className="hero__text__description mb-5">
                                Que trabajarán para administrar sus riesgos y
                                generar estrategias dinámicas que minimizan los
                                peligros potenciales dentro de su compañía.
                            </p>
                            <Button
                                variant="hero"
                                onClick={() => navigate("/contactenos")}
                                size="lg"
                            >
                                Contáctenos
                            </Button>
                        </Container>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    );
}
