import React from "react";
import Navegacion from "../components/Navegacion/Navegacion";
import Frase from "../components/frase/Frase";
import Footer from "../components/Footer/Footer";
import Certificaciones from "../components/certificaciones/Certificaciones";
import Cosinte from "../components/cosinte/Cosinte";
import { Helmet } from "react-helmet";
import TituloPagina from "../components/tituloPagina/TituloPagina";

export default function Nosotros() {
  const ldJson = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": "https://www.cosinte.com/nosotros/",
        url: "https://www.cosinte.com/nosotros/",
        name: "Nosotros - Cosinte",
        isPartOf: { "@id": "https://www.cosinte.com/#website" },
        datePublished: "2021-06-16T00:07:24+00:00",
        dateModified: "2023-03-27T18:58:00+00:00",
        breadcrumb: { "@id": "https://www.cosinte.com/nosotros/#breadcrumb" },
        inLanguage: "es",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://www.cosinte.com/nosotros/"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://www.cosinte.com/nosotros/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Portada",
            item: "https://www.cosinte.com/",
          },
          { "@type": "ListItem", position: 2, name: "Nosotros" },
        ],
      },
      {
        "@type": "WebSite",
        "@id": "https://www.cosinte.com/#website",
        url: "https://www.cosinte.com/",
        name: "Cosinte",
        description: "Expertos en Seguridad Preventiva",
        potentialAction: [
          {
            "@type": "SearchAction",
            target: {
              "@type": "EntryPoint",
              urlTemplate: "https://www.cosinte.com/?s={search_term_string}",
            },
            "query-input": "required name=search_term_string",
          },
        ],
        inLanguage: "es",
      },
    ],
  };
  return (
    <div>
      <Helmet>
        <title>Nosotros - Cosinte</title>
        <link rel="canonical" href="https://www.cosinte.com/nosotros/" />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:title" content="Nosotros - Cosinte" />
        <meta property="og:url" content="https://www.cosinte.com/nosotros/" />
        <meta property="og:image" content="../../assets/logo-cosinte-blanco.png" />
        <meta property="og:description" content="¿POR QUÉ COSINTE? 
        Somos una organización especializada en la protección a todos los niveles.
        Controlamos y prevenimos las pérdidas de su empresa." />
        <meta
          property="article:modified_time"
          content="2023-03-27T18:58:00+00:00"
        />
        <meta name="twitter:data1" content="21 minutos" />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        <script>{`gtag('event', 'conversion', {'send_to': 'AW-999486799/TKebCLTrkdYYEM_qy9wD'});`}</script>
      </Helmet>
      <Navegacion></Navegacion>
      <div className="position-relative mb-5">
      <TituloPagina titulo={'NOSOTROS'}></TituloPagina>
      <Cosinte></Cosinte>
      </div>
      <Certificaciones></Certificaciones>
      <Frase></Frase>
      <Footer></Footer>
    </div>
  );
}
